import { $enum } from 'ts-enum-util';

import {
  RecordedGreetingApprovalStatus,
  FacilityTypeEnum,
  TaskType,
  IvrFailureReason,
  ProviderInfo_NetworkStatus,
} from '@infinitus/generated/frontend-common';
import { infinitusai } from '@infinitus/proto/pbjs';

export function getTaskTypeDisplayName(taskType: TaskType | infinitusai.be.TaskType) {
  switch (taskType) {
    case TaskType.TASK_TYPE_MED_SUPP_BV:
    case infinitusai.be.TaskType.TASK_TYPE_MED_SUPP_BV:
      return 'Medicare Supplement Benefits Verification';
    case TaskType.TASK_TYPE_FULL_BI:
    case infinitusai.be.TaskType.TASK_TYPE_FULL_BI:
      return 'Full Benefits Verification';
    case TaskType.TASK_TYPE_PBM_BV:
    case infinitusai.be.TaskType.TASK_TYPE_PBM_BV:
      return 'Pharmacy Benefits Verification';
    case TaskType.TASK_TYPE_COVID_VACCINATIONS:
    case infinitusai.be.TaskType.TASK_TYPE_COVID_VACCINATIONS:
      return 'Covid Vaccination Availability';
    case TaskType.TASK_TYPE_PRESCRIPTION_TRANSFER:
    case infinitusai.be.TaskType.TASK_TYPE_PRESCRIPTION_TRANSFER:
      return 'Prescription Transfer';
    case TaskType.TASK_TYPE_PRESCRIPTION_SAVINGS:
    case infinitusai.be.TaskType.TASK_TYPE_PRESCRIPTION_SAVINGS:
      return 'Prescription Savings';
    case TaskType.TASK_TYPE_CLAIMS_FOLLOWUP:
    case infinitusai.be.TaskType.TASK_TYPE_CLAIMS_FOLLOWUP:
      return 'Claims Follow Up';
    case TaskType.TASK_TYPE_PRIOR_AUTH_FOLLOWUP:
    case infinitusai.be.TaskType.TASK_TYPE_PRIOR_AUTH_FOLLOWUP:
      return 'Prior Auth Follow Up';
    case TaskType.TASK_TYPE_DENTAL_BV:
    case infinitusai.be.TaskType.TASK_TYPE_DENTAL_BV:
      return 'Dental Benefits Verification';
    case TaskType.TASK_TYPE_SHIPMENT_FOLLOWUP:
    case infinitusai.be.TaskType.TASK_TYPE_SHIPMENT_FOLLOWUP:
      return 'Shipment Follow Up';
    case TaskType.TASK_TYPE_PATIENT_OUTREACH:
    case infinitusai.be.TaskType.TASK_TYPE_PATIENT_OUTREACH:
      return 'Patient Outreach';
    case TaskType.TASK_TYPE_PROVIDER_OUTREACH:
    case infinitusai.be.TaskType.TASK_TYPE_PROVIDER_OUTREACH:
      return 'Provider Outreach';
    case TaskType.TASK_TYPE_FASTTRACK_MM:
    case infinitusai.be.TaskType.TASK_TYPE_FASTTRACK_MM:
      return 'FastTrack Major Medical';
    case TaskType.TASK_TYPE_FASTTRACK_PBM:
    case infinitusai.be.TaskType.TASK_TYPE_FASTTRACK_PBM:
      return 'FastTrack Pharmacy Benefit Manager';
    case TaskType.TASK_TYPE_PHARMACY_STOCK_CHECK:
    case infinitusai.be.TaskType.TASK_TYPE_PHARMACY_STOCK_CHECK:
      return 'Pharmacy Stock Check';
    case TaskType.TASK_TYPE_BASIC_BV:
    case infinitusai.be.TaskType.TASK_TYPE_BASIC_BV:
      return 'Basic Benefits Verification';
    case TaskType.TASK_TYPE_FASTTRACK_CLAIMS_APPEAL:
    case infinitusai.be.TaskType.TASK_TYPE_FASTTRACK_CLAIMS_APPEAL:
      return 'FastTrack Claims Appeal';
    case TaskType.TASK_TYPE_FASTTRACK_CLAIMS_DENIAL:
    case infinitusai.be.TaskType.TASK_TYPE_FASTTRACK_CLAIMS_DENIAL:
      return 'FastTrack Claims Denial';
    case TaskType.TASK_TYPE_FASTTRACK_CLAIMS_FOLLOWUP:
    case infinitusai.be.TaskType.TASK_TYPE_FASTTRACK_CLAIMS_FOLLOWUP:
      return 'FastTrack Claims Follow Up';
    case TaskType.TASK_TYPE_PBM_DISCOVERY:
    case infinitusai.be.TaskType.TASK_TYPE_PBM_DISCOVERY:
      return 'Pharmacy Benefit Manager Discovery';
    case TaskType.TASK_TYPE_HEALTH_RISK_ASSESSMENT:
    case infinitusai.be.TaskType.TASK_TYPE_HEALTH_RISK_ASSESSMENT:
      return 'Health Risk Assessment';
    default:
      return 'Unknown';
  }
}

export const getTaskTypeDisplayNamePortal = (
  taskType: infinitusai.be.TaskType | keyof typeof infinitusai.be.TaskType
): string => {
  if (!taskType) return 'Unknown';
  const enumValue = typeof taskType === 'string' ? infinitusai.be.TaskType[taskType] : taskType;
  switch (enumValue) {
    case infinitusai.be.TaskType.TASK_TYPE_FULL_BI:
      return 'Major Medical';
    case infinitusai.be.TaskType.TASK_TYPE_PBM_BV:
      return 'PBM';
    case infinitusai.be.TaskType.TASK_TYPE_PRESCRIPTION_TRANSFER:
      return 'Prescription Transfer';
    case infinitusai.be.TaskType.TASK_TYPE_PRESCRIPTION_SAVINGS:
      return 'Prescription Savings';
    case infinitusai.be.TaskType.TASK_TYPE_CLAIMS_FOLLOWUP:
      return 'Claims Follow Up';
    case infinitusai.be.TaskType.TASK_TYPE_PRIOR_AUTH_FOLLOWUP:
      return 'Prior Auth Follow Up';
    case infinitusai.be.TaskType.TASK_TYPE_DENTAL_BV:
      return 'Dental BV';
    case infinitusai.be.TaskType.TASK_TYPE_SHIPMENT_FOLLOWUP:
      return 'Shipment Follow Up';
    case infinitusai.be.TaskType.TASK_TYPE_PATIENT_OUTREACH:
      return 'Patient Outreach';
    case infinitusai.be.TaskType.TASK_TYPE_PROVIDER_OUTREACH:
      return 'Provider Outreach';
    case infinitusai.be.TaskType.TASK_TYPE_PHARMACY_STOCK_CHECK:
      return 'Pharmacy Stock Check';
    case infinitusai.be.TaskType.TASK_TYPE_BASIC_BV:
      return 'Basic Benefits Verification';
    case infinitusai.be.TaskType.TASK_TYPE_PBM_DISCOVERY:
      return 'PBM Discovery';
    default:
      return 'Unknown';
  }
};

export const getFacilityTypeDisplayName = (
  facilityType: FacilityTypeEnum | infinitusai.be.FacilityType.Type
): string => {
  if (!facilityType) return 'Unknown';
  switch (facilityType) {
    case infinitusai.be.FacilityType.Type.HOSPITAL_OUTPATIENT:
    case FacilityTypeEnum.HOSPITAL_OUTPATIENT:
      return 'Hospital Outpatient';
    case infinitusai.be.FacilityType.Type.PHARMACY:
    case FacilityTypeEnum.PHARMACY:
      return 'Pharmacy';
    case infinitusai.be.FacilityType.Type.SPECIALIST_OFFICE:
    case FacilityTypeEnum.SPECIALIST_OFFICE:
      return 'Specialist Office';
    case infinitusai.be.FacilityType.Type.SHIP_TO_HOME:
    case FacilityTypeEnum.SHIP_TO_HOME:
      return 'Ship to Home';
    case infinitusai.be.FacilityType.Type.INFUSION_CENTER:
    case FacilityTypeEnum.INFUSION_CENTER:
      return 'Infusion Center';
    case infinitusai.be.FacilityType.Type.AMBULATORY_SURGICAL_CENTER:
    case FacilityTypeEnum.AMBULATORY_SURGICAL_CENTER:
      return 'Ambulatory Surgical Center';
    case infinitusai.be.FacilityType.Type.HOME_INFUSION:
    case FacilityTypeEnum.HOME_INFUSION:
      return 'Home Infusion';
    case infinitusai.be.FacilityType.Type.FED_QUALIFIED_HC:
    case FacilityTypeEnum.FED_QUALIFIED_HC:
      return 'Federally Qualified Health Center';
    case infinitusai.be.FacilityType.Type.HOSPICE:
    case FacilityTypeEnum.HOSPICE:
      return 'Hospice';
    case infinitusai.be.FacilityType.Type.HOSPITAL_INPATIENT:
    case FacilityTypeEnum.HOSPITAL_INPATIENT:
      return 'Hospital Inpatient';
    case infinitusai.be.FacilityType.Type.HOSPITAL_OUTPATIENT_OFF_CAMPUS:
    case FacilityTypeEnum.HOSPITAL_OUTPATIENT_OFF_CAMPUS:
      return 'Hospital Off-Campus Outpatient';
    case infinitusai.be.FacilityType.Type.IHC:
    case FacilityTypeEnum.IHC:
      return 'IHC';
    case infinitusai.be.FacilityType.Type.INPATIENT_REHAB:
    case FacilityTypeEnum.INPATIENT_REHAB:
      return 'Inpatient Rehab';
    case infinitusai.be.FacilityType.Type.OUTPATIENT_REHAB:
    case FacilityTypeEnum.OUTPATIENT_REHAB:
      return 'Outpatient Rehab';
    case infinitusai.be.FacilityType.Type.PUBLIC_HEALTH_CLINIC:
    case FacilityTypeEnum.PUBLIC_HEALTH_CLINIC:
      return 'Public Health Clinic';
    case infinitusai.be.FacilityType.Type.RURAL_HEALTH_CLINIC:
    case FacilityTypeEnum.RURAL_HEALTH_CLINIC:
      return 'Rural Health Clinic';
    case infinitusai.be.FacilityType.Type.SNF:
    case FacilityTypeEnum.SNF:
      return 'Skilled Nursing Facility';
    case infinitusai.be.FacilityType.Type.DIAGNOSTIC_TEST_LAB:
    case FacilityTypeEnum.DIAGNOSTIC_TEST_LAB:
      return 'Diagnostic Test Lab';
    default:
      return 'Unknown';
  }
};

export function getRecordedGreetingApprovalStatusDisplayName(type: RecordedGreetingApprovalStatus) {
  switch (type) {
    case RecordedGreetingApprovalStatus.GREETING_APPROVED:
      return 'Approved';
    case RecordedGreetingApprovalStatus.GREETING_PENDING:
      return 'Pending Approval';
    case RecordedGreetingApprovalStatus.GREETING_REJECTED:
      return 'Rejected';
    default:
      return 'Unknown';
  }
}

export function getIvrFailureReasonDisplayName(type: IvrFailureReason) {
  switch (type) {
    case IvrFailureReason.PATIENT_DOB:
      return "The patient's date of birth was not recognized";
    case IvrFailureReason.PATIENT_ID:
      return "The patient's ID was not recognized";
    case IvrFailureReason.PROVIDER_NPI:
      return "The provider's NPI was not recognized";
    case IvrFailureReason.PROVIDER_TAX_ID:
      return 'The provider tax ID was not recognized';
    case IvrFailureReason.IVR_CLOSED:
      return 'The IVR was closed';
    default:
      return 'Unknown error';
  }
}

export enum OutboundHangupClassification {
  OutboundHangup = 'Outbound Hangup',
  ExternalCloudServiceError = 'External Cloud Service Error',
  InternalError = 'Internal Error',
  HoldTimeError = 'Hold Time Error',
  UnknownError = 'Unknown Error',
  PayerIsClosed = 'Payer Closed',
  PayerSystemIssue = 'Payer System Issue',
  IvrTimeout = 'Maximum IVR Time Reached',
}

export const OutboundHangupClassificationToMuiAlertSeverityMap = new Map<
  string,
  'error' | 'warning' | 'info' | 'success'
>([
  [OutboundHangupClassification.OutboundHangup, 'info'],
  [OutboundHangupClassification.ExternalCloudServiceError, 'error'],
  [OutboundHangupClassification.InternalError, 'error'],
  [OutboundHangupClassification.HoldTimeError, 'error'],
  [OutboundHangupClassification.IvrTimeout, 'warning'],
]);

const hangupErrorTypeToDisplayNameMap = new Map<string, string>([
  ['SpeechToText', OutboundHangupClassification.ExternalCloudServiceError],
  ['TextToSpeech', OutboundHangupClassification.ExternalCloudServiceError],
  ['Firestore', OutboundHangupClassification.ExternalCloudServiceError],
  ['Nexmo', OutboundHangupClassification.ExternalCloudServiceError],
  ['Redis', OutboundHangupClassification.InternalError],
  ['NLPSystem', OutboundHangupClassification.InternalError],
  ['Audiocodes', OutboundHangupClassification.InternalError],
  ['Recorder', OutboundHangupClassification.InternalError],
  ['ProcessSignal', OutboundHangupClassification.InternalError],
  ['Internal', OutboundHangupClassification.InternalError],
  ['OperatorTrainerCallIdle', OutboundHangupClassification.InternalError],
  ['UnableToQueryForOperator', OutboundHangupClassification.InternalError],
  ['NoOperatorsAvailable', OutboundHangupClassification.InternalError],
  ['FailedToBegForOperator', OutboundHangupClassification.InternalError],
  ['FailedRoutingOperator', OutboundHangupClassification.InternalError],
  ['TerminatingUtterance', OutboundHangupClassification.InternalError],
  ['LongHoldTime', OutboundHangupClassification.HoldTimeError],
  ['RecordedGreeting', OutboundHangupClassification.InternalError],
  ['RecordingFailure', OutboundHangupClassification.InternalError],
  ['MonitorService', OutboundHangupClassification.InternalError],
  ['Hangup', OutboundHangupClassification.OutboundHangup],
  ['IVR', OutboundHangupClassification.IvrTimeout],
]);

export function isValidNLPHangupReason(hangupReason: string) {
  //check the hangup reason and return the appropriate display name if the NLP hangup reason is found
  //we use NLP hangup reasons to determine if we should show a warning or error alert
  //some NLP hangup reasons are "good" such as payer closed, so we want diff alerts for them
  const hangupReasonEnum = $enum(infinitusai.be.RequeueReason.Type).getValueOrDefault(
    hangupReason,
    infinitusai.be.RequeueReason.Type.REASON_UNKNOWN
  );

  const warningReasons = [
    infinitusai.be.RequeueReason.Type.REASON_NLP_PAYER_UNREACHABLE,
    infinitusai.be.RequeueReason.Type.REASON_PAYER_SYSTEM_ISSUE,
  ];

  return warningReasons.includes(hangupReasonEnum);
}

export function getHangupReasonDisplayName(errorType: string, hangupReason: string) {
  const hangupReasonEnum = $enum(infinitusai.be.RequeueReason.Type).getValueOrDefault(
    hangupReason,
    infinitusai.be.RequeueReason.Type.REASON_UNKNOWN
  );

  //check the hangup reason and return the appropriate display name if the NLP hangup reason is found
  if (hangupReasonEnum === infinitusai.be.RequeueReason.Type.REASON_NLP_PAYER_UNREACHABLE) {
    return OutboundHangupClassification.PayerIsClosed;
  }
  if (hangupReasonEnum === infinitusai.be.RequeueReason.Type.REASON_PAYER_SYSTEM_ISSUE) {
    return OutboundHangupClassification.PayerSystemIssue;
  }
  //No NLP hangup reason we can just use the error class for the classification
  return (
    hangupErrorTypeToDisplayNameMap.get(errorType) || OutboundHangupClassification.InternalError
  );
}

export function getHangupReasonAlertSeverity(
  errorType: string,
  hangupReason: string
): 'error' | 'warning' | 'info' | 'success' {
  if (isValidNLPHangupReason(hangupReason)) {
    return 'warning';
  }

  //No NLP hangup reason we can just use the error class for the classification
  const hangupClassification =
    hangupErrorTypeToDisplayNameMap.get(errorType) || OutboundHangupClassification.InternalError;
  return OutboundHangupClassificationToMuiAlertSeverityMap.get(hangupClassification) || 'error';
}

export function getNetworkStatusDisplayName(
  type: ProviderInfo_NetworkStatus | infinitusai.be.ProviderInfo.NetworkStatus
): string {
  switch (type) {
    case ProviderInfo_NetworkStatus.NETWORK_STATUS_IN_NETWORK:
    case infinitusai.be.ProviderInfo.NetworkStatus.NETWORK_STATUS_IN_NETWORK:
      return 'In Network';
    case ProviderInfo_NetworkStatus.NETWORK_STATUS_OUT_OF_NETWORK:
    case infinitusai.be.ProviderInfo.NetworkStatus.NETWORK_STATUS_OUT_OF_NETWORK:
      return 'Out of Network';
    default:
      return 'Unknown';
  }
}
