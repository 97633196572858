import { AppName } from '@infinitusai/api';
import { Alert, AlertTitle, Box, Button, Paper } from '@mui/material';
import { User } from 'firebase/auth';
import { isEqual, isFunction } from 'lodash';
import { SnackbarContent } from 'notistack';
import { PropsWithChildren, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSessionStorage } from 'usehooks-ts';

import useSnackbar from '@infinitus/hooks/useCustomSnackbar';
import useInterval from '@infinitus/hooks/useInterval';
import { infinitusai } from '@infinitus/proto/pbjs';
import OperatorPresenceService, {
  OperatorPresenceServiceEvents,
} from '@infinitus/services/OperatorPresenceService';
import { SESSION_STORAGE_AUTO_JOIN_QUEUE_KEY } from '@infinitus/utils/sessionStorage';

import OperatorPresenceContext from './OperatorPresenceContext';
import { HeartbeatMessageType, messageTypeToPageTypeProto } from './types';
import usePresenceHeartbeat from './usePresenceMessage';

// TODO: re-enable when we can query the backend for currentUserActivity
// import useSound from 'use-sound';
// TODO: re-enable when we can query the backend for currentUserActivity
// import alertSound from './sounds/alert-chime.mp3';
export const HEARTBEAT_INTERVAL = 2000;
// If we haven't had an operator queue update after this duration, we assume we've lost the websocket connection
const OPERATOR_QUEUE_UPDATES_TIMEOUT = HEARTBEAT_INTERVAL * 5;
const WEBSOCKET_ERROR_SNACKBAR_KEY = 'websocketError';

export interface OperatorPresenceProviderProps {
  appName: AppName;
  onDismissNotification?: (notification: infinitusai.be.DismissNotification) => void;
  onNotificationReceived?: (notification: infinitusai.be.Notification) => void;
  onOperatorShouldJoinCall?: (
    joinCallMessage: infinitusai.be.OperatorShouldJoinCallMessage
  ) => Promise<void>;
  onOperatorShouldLeaveCall?: (
    leaveCallMessage: infinitusai.be.OperatorShouldLeaveCallMessage
  ) => void;
  orgUuid: string;
  user?: User | null;
}

export default function OperatorPresenceProvider({
  user,
  appName,
  children,
  orgUuid,
  onNotificationReceived,
  onDismissNotification,
  onOperatorShouldJoinCall,
  onOperatorShouldLeaveCall,
}: PropsWithChildren<OperatorPresenceProviderProps>) {
  // Used to persist the user's ready-page availability choice for their browser session
  const [operatorReadyToJoinCalls, setOperatorReadyToJoinCalls] = useSessionStorage(
    SESSION_STORAGE_AUTO_JOIN_QUEUE_KEY,
    false
  );
  const [operatorsInQueue, setOperatorsInQueue] = useState<null | string[]>(null);

  // Micro review state and to show the micro review model
  const [microReviewData, setMicroReviewData] = useState<
    infinitusai.be.IOperatorRouteToMicroReviewMessage | undefined
  >(undefined);

  // The heartbeatMessageType state var tracks the type of page we're currently on, which
  // changes the type of heartbeat we send to the server.
  const {
    setHeartbeatMessageType,
    getHeartbeatMessageType,
    setHeartbeatMessage,
    getHeartbeatMessage,
  } = usePresenceHeartbeat();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  // Used to monitor the operator queue updates and display an error if we have stopped receiving updates
  const operatorQueueHeartbeatTimer = useRef<number>(0);
  const handleOperatorQueueUpdatesTimeout = useRef<() => void>(() => {});
  // This callback can be undefined - it is only defined when we are in the error state
  const handleOperatorQueueUpdatesRestored = useRef<(() => void) | undefined>();

  // Cleanup on mount & unmount
  useEffect(() => {
    window.clearTimeout(operatorQueueHeartbeatTimer.current ?? 0);

    return function unmount() {
      window.clearTimeout(operatorQueueHeartbeatTimer.current ?? 0);
      // ensure the  websocket is disconnected on unmount
      if (OperatorPresenceService.isConnected) {
        OperatorPresenceService.disconnect();
      }
    };
  }, []);

  // Called when no operator queue updates are received for a while (which indicates a broken websocket connection)
  handleOperatorQueueUpdatesTimeout.current = useCallback(() => {
    //TODO: CHECK THIS AGAIN
    const onActiveCallsPage = /operator\/.+\/active-calls/.test(window.location.href);
    if (!onActiveCallsPage) {
      return;
    }
    setOperatorsInQueue(null);
    setOperatorReadyToJoinCalls(false);
    enqueueSnackbar('', {
      key: WEBSOCKET_ERROR_SNACKBAR_KEY,
      variant: 'info',
      content: (
        <SnackbarContent style={{ pointerEvents: 'all' }}>
          <Paper elevation={6}>
            <Alert severity="error">
              <AlertTitle>Network connection issue</AlertTitle>
              Due to a connection issue with your websocket, you have been taken offline. Reload
              your page to reconnect.
              <Box mt={1}>
                <Button
                  onClick={() => {
                    window.location.reload();
                  }}
                  variant="outlined"
                >
                  Reload page
                </Button>
              </Box>
            </Alert>
          </Paper>
        </SnackbarContent>
      ),
      persist: true,
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'center',
      },
    });
    console.error(
      `Websocket: No server-originated operator queue messages received for ${OPERATOR_QUEUE_UPDATES_TIMEOUT}ms, displayed network connection error to operator '${user?.email}'.`
    );

    // A callback in case operator queue updates are restored without user interaction
    handleOperatorQueueUpdatesRestored.current = () => {
      closeSnackbar(WEBSOCKET_ERROR_SNACKBAR_KEY);
      setOperatorReadyToJoinCalls(operatorReadyToJoinCalls);
      enqueueSnackbar(
        'Your websocket connection has now been restored, although you may have lost your place in the queue.',
        {
          variant: 'info',
          autoHideDuration: 8000,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
        }
      );
      console.log(
        `Websocket: Server-originated operator queue messages received again - auto-dismissed network connection error for operator '${user?.email}'.`
      );
      handleOperatorQueueUpdatesRestored.current = undefined;
    };
  }, [
    closeSnackbar,
    enqueueSnackbar,
    operatorReadyToJoinCalls,
    setOperatorReadyToJoinCalls,
    user?.email,
  ]);

  // Ensures that we lock the operator presence polling mechanism when a call is being requeued
  // to avoid re-registering the operator as they exit the page (as that will prevent future routing of operators).
  const [isCallBeingRequeuedSemaphore, setIsCallBeingRequeuedSemaphore] = useState(false);

  const onOperatorQueue = useCallback(
    (data: infinitusai.be.OperatorQueueMessage) => {
      window.clearTimeout(operatorQueueHeartbeatTimer.current ?? 0);
      // If this is a restoring of the websocket connection, then invoke the callback
      if (isFunction(handleOperatorQueueUpdatesRestored.current)) {
        handleOperatorQueueUpdatesRestored.current();
      }
      operatorQueueHeartbeatTimer.current = window.setTimeout(
        () => handleOperatorQueueUpdatesTimeout.current(),
        OPERATOR_QUEUE_UPDATES_TIMEOUT
      );
      if (!isEqual(operatorsInQueue, data.operatorsInQueue)) {
        setOperatorsInQueue(data.operatorsInQueue);
      }
    },
    [handleOperatorQueueUpdatesTimeout, operatorsInQueue]
  );

  const onNotificationReceivedRef = useRef(onNotificationReceived);
  onNotificationReceivedRef.current = onNotificationReceived;
  const onNotificationReceivedCallback = useCallback((data: infinitusai.be.Notification) => {
    onNotificationReceivedRef.current?.(data);
  }, []);

  const onDismissNotificationRef = useRef(onDismissNotification);
  onDismissNotificationRef.current = onDismissNotification;
  const onDismissNotificationCallback = useCallback((data: infinitusai.be.DismissNotification) => {
    onDismissNotificationRef.current?.(data);
  }, []);

  const onOperatorShouldLeaveCallRef = useRef(onOperatorShouldLeaveCall);
  onOperatorShouldLeaveCallRef.current = onOperatorShouldLeaveCall;
  const onOperatorShouldLeaveCallCallback = useCallback(
    (data: infinitusai.be.OperatorShouldLeaveCallMessage) => {
      onOperatorShouldLeaveCallRef.current?.(data);
    },
    []
  );

  const onOperatorShouldJoinCallRef = useRef(onOperatorShouldJoinCall);
  onOperatorShouldJoinCallRef.current = onOperatorShouldJoinCall;
  const onOperatorShouldJoinCallback = useCallback(
    async (data: infinitusai.be.OperatorShouldJoinCallMessage) => {
      console.log(
        `Received an OperatorShouldJoinCallMessage with payload ${JSON.stringify(
          data
        )} via the websocket connection...`
      );
      if (getHeartbeatMessageType() !== HeartbeatMessageType.READY_PAGE) {
        console.log('Ignoring OperatorShouldJoinCallMessage because we are not on the ready page.');
        return;
      }
      if (!operatorReadyToJoinCalls) {
        console.log(
          'Ignoring OperatorShouldJoinCallMessage because we are not ready to join calls on this ready page.'
        );
        return;
      }
      try {
        void onOperatorShouldJoinCallRef.current?.(data);
      } catch (err) {
        console.error('err', err);
      }
    },
    [getHeartbeatMessageType, operatorReadyToJoinCalls]
  );

  const onRouteReviewerToMicroReviewCallback = useCallback(
    async (data: infinitusai.be.OperatorRouteToMicroReviewMessage) => {
      if (getHeartbeatMessageType() !== HeartbeatMessageType.READY_PAGE) {
        console.log(
          'Ignoring OperatorRouteToMicroReviewMessage because we are not on the ready page.'
        );
        return;
      }
      if (!operatorReadyToJoinCalls) {
        console.log(
          'Ignoring OperatorRouteToMicroReviewMessage because we are not ready to join calls on this ready page.'
        );
        return;
      }
      setMicroReviewData(data);
    },
    [getHeartbeatMessageType, operatorReadyToJoinCalls]
  );

  const bindWebsocketEventListeners = useCallback(() => {
    OperatorPresenceService.removeAllListeners();
    OperatorPresenceService.on(OperatorPresenceServiceEvents.OPERATOR_QUEUE, onOperatorQueue);
    OperatorPresenceService.on(
      OperatorPresenceServiceEvents.OPERATOR_SHOULD_JOIN_CALL,
      onOperatorShouldJoinCallback
    );
    OperatorPresenceService.on(
      OperatorPresenceServiceEvents.OPERATOR_SHOULD_LEAVE_CALL,
      onOperatorShouldLeaveCallCallback
    );
    OperatorPresenceService.on(
      OperatorPresenceServiceEvents.NOTIFICATION_RECEIVED,
      onNotificationReceivedCallback
    );
    OperatorPresenceService.on(
      OperatorPresenceServiceEvents.DISMISS_NOTIFICATION,
      onDismissNotificationCallback
    );
    OperatorPresenceService.on(
      OperatorPresenceServiceEvents.ROUTE_REVIEWER_TO_MICRO_REVIEW,
      onRouteReviewerToMicroReviewCallback
    );
  }, [
    onOperatorQueue,
    onOperatorShouldJoinCallback,
    onOperatorShouldLeaveCallCallback,
    onNotificationReceivedCallback,
    onDismissNotificationCallback,
    onRouteReviewerToMicroReviewCallback,
  ]);

  // Maybe Re-auth OperatorPresenceService when switching orgs if previous initializations failed
  useEffect(() => {
    const initializePresenceService = async () => {
      // Assert that we're signed in before attempting to query the backend
      if (user) {
        try {
          await OperatorPresenceService.initialize(orgUuid, appName);
          bindWebsocketEventListeners();
        } catch (e) {
          console.error(`Failed to init websocket strategy: ${e}`);
        }
      }
    };

    void initializePresenceService();
    return () => {
      OperatorPresenceService.removeAllListeners();
    };
  }, [appName, bindWebsocketEventListeners, orgUuid, user]);

  const application = useMemo(() => {
    switch (appName) {
      case AppName.FASTTRACK:
        return infinitusai.be.HeartbeatMessageFromClient.Application.FASTTRACK;
      case AppName.OPERATOR:
        return infinitusai.be.HeartbeatMessageFromClient.Application.OPERATOR_PORTAL;
      default:
        return infinitusai.be.HeartbeatMessageFromClient.Application.UNKNOWN_APPLICATION;
    }
  }, [appName]);

  const sendHeartbeat = useCallback(async () => {
    if (!OperatorPresenceService.isConnected) return;
    if (isCallBeingRequeuedSemaphore) return;

    const heartbeatPayload: infinitusai.be.IHeartbeatMessageFromClient = getHeartbeatMessage();
    heartbeatPayload.application = application;
    heartbeatPayload.pageType = messageTypeToPageTypeProto(getHeartbeatMessageType());

    OperatorPresenceService.sendHeartbeat(heartbeatPayload);
  }, [application, getHeartbeatMessage, getHeartbeatMessageType, isCallBeingRequeuedSemaphore]);

  useInterval(() => {
    void sendHeartbeat();
  }, HEARTBEAT_INTERVAL);

  const setPageHeartbeat = useCallback(
    (
      heartbeatMessageType: HeartbeatMessageType,
      heartbeatMessage: infinitusai.be.IHeartbeatMessageFromClient
    ) => {
      if (heartbeatMessageType !== getHeartbeatMessageType()) {
        setHeartbeatMessageType(heartbeatMessageType);
      }
      setHeartbeatMessage(heartbeatMessage);
    },
    [getHeartbeatMessageType, setHeartbeatMessage, setHeartbeatMessageType]
  );

  const resetPageHeartbeat = useCallback(() => {
    setPageHeartbeat(HeartbeatMessageType.OTHER_PAGE, {
      unknownPageData: infinitusai.be.UnknownPageClientHeartbeat.fromObject({}),
    });
  }, [setPageHeartbeat]);

  const contextValue = useMemo(
    () => ({
      setPageHeartbeat,
      resetPageHeartbeat,
      sendHeartbeat,
      operatorsInQueue,
      operatorReadyToJoinCalls,
      setOperatorReadyToJoinCalls,
      isCallBeingRequeuedSemaphore,
      setIsCallBeingRequeuedSemaphore,
      microReviewData,
      setMicroReviewData,
    }),
    [
      setPageHeartbeat,
      resetPageHeartbeat,
      sendHeartbeat,
      operatorsInQueue,
      operatorReadyToJoinCalls,
      setOperatorReadyToJoinCalls,
      isCallBeingRequeuedSemaphore,
      setIsCallBeingRequeuedSemaphore,
      microReviewData,
      setMicroReviewData,
    ]
  );

  return (
    <OperatorPresenceContext.Provider value={contextValue}>
      {children}
    </OperatorPresenceContext.Provider>
  );
}
