import { useAuth, UserType, PermissionName } from '@infinitusai/auth';
import {
  useOperatorPortalFeatureEnabled,
  OperatorPortalFeature,
  useAppState,
} from '@infinitusai/shared';
import List from '@mui/material/List';
import { useMemo } from 'react';
import { useMatch } from 'react-router-dom';

import { IconNames } from '@infinitus/components/Icon';
import useConditionalSx from '@infinitus/hooks/useConditionalSx';
import { useCanShowTaskList } from 'hooks/useCanShowTaskList';

import { expandedNavSx, baseNavSx } from './AppNavStyles';
import { useNavState } from './navState';
import NavItem from './subcomponents/NavItem';
import { NavItemList } from './types';

const makeOpsRoute = (orgName: string, slug?: string) => {
  return `${orgName}/operator${slug ? `/${slug}` : ''}`;
};
const makeAdminRoute = (orgName: string, slug: string) => {
  return `${orgName}/admin/${slug}`;
};

interface RouteProps {
  canShowTaskList: boolean;
  isAdmin: boolean;
  orgName: string;
  showUnifiedQueue: boolean;
}

const getRoutes = ({
  isAdmin,
  orgName,
  showUnifiedQueue,
  canShowTaskList,
}: RouteProps): NavItemList => {
  var navArr: any[] = [];
  if (showUnifiedQueue) {
    navArr = [
      {
        href: `${orgName}/queue`,
        iconName: IconNames.JOIN_INNER,
        id: 'queue',
        title: 'Unified queue',
      },
    ];
  } else {
    // Show org queue
    navArr = [
      {
        href: makeOpsRoute(orgName, 'ready'),
        id: 'ready',
        iconName: IconNames.RING_VOLUME,
        title: 'Ready page',
      },
    ];
  }
  const operatorRouteTaskChildren = [
    {
      href: makeOpsRoute(
        orgName,
        'task-list/all?state=CALL+CONNECTED&state=CALL+DISCONNECTED&state=READY+FOR+CALL&state=READY+FOR+REVIEW&state=IN+REVIEW&state=READY+FOR+SELF+REVIEW'
      ),
      iconName: IconNames.ASSIGNMENT,
      id: 'all',
      title: 'All tasks',
    },
    {
      href: makeOpsRoute(orgName, 'task-list/pending'),
      id: 'pending',
      iconName: IconNames.PENDING_ACTIONS,
      title: 'Tasks pending',
    },
    {
      href: makeOpsRoute(orgName, 'task-list/in-progress'),
      iconName: IconNames.CONTENT_PASTE_GO,
      id: 'in-progress',
      title: 'Tasks in progress',
    },
    {
      href: makeOpsRoute(orgName, 'task-list/awaiting-review'),
      id: 'awaiting-review',
      iconName: IconNames.CONTENT_PASTE_SEARCH,
      title: 'Tasks awaiting review',
    },
    {
      href: makeOpsRoute(orgName, 'task-list/in-review'),
      iconName: IconNames.INVENTORY,
      iconOutlined: true,
      id: 'in-review',
      title: 'Tasks in review',
    },
  ];

  const taskChildren =
    isAdmin && orgName === 'infinitus_test_pool'
      ? [
          ...operatorRouteTaskChildren,
          {
            href: makeOpsRoute(orgName, 'task-list/tagged-tasks'),
            iconName: IconNames.MISCELLANEOUS_SERVICES,
            iconOutlined: true,
            id: 'tagged-tasks',
            title: 'Tagged tasks',
          },
        ]
      : operatorRouteTaskChildren;
  if (canShowTaskList) {
    navArr.push({
      id: 'task-list',
      iconName: IconNames.ASSIGNMENT,
      iconOutlined: true,
      title: 'Tasks',
      children: taskChildren,
    });
  }
  if (isAdmin) {
    navArr.push(
      {
        id: 'training-parent',
        iconName: IconNames.SCHOOL,
        iconOutlined: true,
        title: 'Training',
        children: [
          {
            href: makeOpsRoute(orgName, 'training'),
            iconName: IconNames.SCHOOL,
            id: 'training',
            title: 'Training',
          },
          {
            id: 'training-scripts',
            iconName: IconNames.ACCOUNT_TREE,
            href: makeAdminRoute(orgName, 'training-scripts?taskType=TASK_TYPE_FULL_BI'),
            title: 'Training scripts',
          },
        ],
      },
      {
        id: 'queue-management-parent',
        iconName: IconNames.DASHBOARD,
        iconOutlined: true,
        title: 'Queue management',
        children: [
          {
            id: 'priorityTasks',
            href: makeAdminRoute(orgName, 'priorityTasks'),
            iconName: IconNames.DASHBOARD,
            title: 'Priority tasks',
          },
          {
            id: 'active-calls',
            href: makeAdminRoute(orgName, 'active-calls'),
            iconName: IconNames.WIFI_CALLING,
            title: 'Active calls',
          },
          {
            id: 'queues',
            href: makeAdminRoute(orgName, 'queues'),
            iconName: IconNames.LIST,
            title: 'Queue editor',
          },
          {
            id: 'unifiedQueueManager',
            href: makeAdminRoute(orgName, 'unifiedQueueManager'),
            iconName: IconNames.DASHBOARD,
            title: 'Unified queue management',
          },
          {
            id: 'skillDefinitions',
            href: makeAdminRoute(orgName, 'skillDefinitions'),
            iconName: IconNames.PSYCHOLOGY,
            title: 'Skill editor',
          },
          {
            id: 'userSkills',
            href: makeAdminRoute(orgName, 'userSkills'),
            iconName: IconNames.PERSON_SEARCH,
            title: 'User skills',
          },
        ],
      },
      {
        id: 'customer-parent',
        iconName: IconNames.WORK_OUTLINE,
        iconOutlined: true,
        title: 'Customers',
        children: [
          {
            id: 'customers',
            href: makeAdminRoute(orgName, 'customers'),
            iconName: IconNames.WORK,
            title: 'Customers',
          },
          {
            id: 'orgs',
            href: makeAdminRoute(orgName, 'orgs'),
            iconName: IconNames.PEOPLE,
            title: 'Orgs',
          },
        ],
      },
      {
        id: 'payers-parent',
        iconName: IconNames.SAVINGS,
        iconOutlined: true,
        title: 'Payers',
        children: [
          {
            id: 'payers',
            href: makeAdminRoute(orgName, 'payers'),
            iconName: IconNames.SAVINGS,
            title: 'Payers',
          },
          {
            id: 'phoneNumbers',
            href: makeAdminRoute(orgName, 'phoneNumbers'),
            iconName: IconNames.DIALPAD,
            title: 'Phone numbers',
          },
        ],
      },
      {
        id: 'convo',
        iconName: IconNames.FORUM,
        href: makeAdminRoute(orgName, orgName ? `convo/${orgName}` : `convo`),
        title: 'Convo builder',
      },
      {
        id: 'CBV2',
        iconName: IconNames.FILTER_2,
        title: 'Convo Builder Version 2',
        children: [
          {
            title: 'Standard Outputs',
            id: 'standard-outputs',
            iconName: IconNames.OUTPUT,
            href: makeAdminRoute(orgName, 'library/standard-outputs'),
          },
          {
            id: 'standard-conditions',
            iconName: IconNames.FORK_RIGHT,
            href: makeAdminRoute(orgName, 'library/standard-conditions'),
            title: 'Standard Conditions',
          },
          {
            id: 'standard-actions-utterances',
            iconName: IconNames.ASSISTANT,
            iconOutlined: true,
            href: makeAdminRoute(orgName, 'library/standard-actions-utterances'),
            title: 'Standard Actions and Utterances',
          },
          {
            id: 'standard-modules',
            iconName: IconNames.ACCOUNT_TREE,
            iconOutlined: true,
            href: makeAdminRoute(orgName, 'library/standard-modules'),
            title: 'Standard Modules',
          },
        ],
      },
      {
        id: 'library',
        iconName: IconNames.COLLECTIONS_BOOKMARK,
        iconOutlined: true,
        title: 'Library',
        children: [
          {
            id: 'outputs',
            iconName: IconNames.OUTPUT,
            href: makeAdminRoute(orgName, 'library/outputs'),
            title: 'Outputs',
          },
          {
            id: 'autocomplete',
            href: makeAdminRoute(orgName, 'library/autocomplete'),
            iconName: IconNames.LOCATION_SEARCHING,
            title: 'Autocomplete options',
          },
          {
            id: 'conditions',
            iconName: IconNames.FORK_RIGHT,
            href: makeAdminRoute(
              orgName,
              orgName ? `library/conditions/${orgName}` : 'library/conditions'
            ),
            title: 'Conditions',
          },
          {
            id: 'products',
            href: makeAdminRoute(orgName, 'library/products'),
            iconName: IconNames.VACCINES,
            title: 'Products',
          },
          {
            id: 'integration-testing',
            href: makeAdminRoute(orgName, 'library/integration-testing'),
            iconName: IconNames.MISCELLANEOUS_SERVICES,
            title: 'Self Service Integration Testing Tags',
          },
          {
            id: 'cpt-library',
            href: makeAdminRoute(orgName, 'library/cpt'),
            iconName: IconNames.MEDICATION_LIQUID,
            title: 'CPT Information Library',
          },
          {
            id: 'ebv',
            href: makeAdminRoute(orgName, 'library/ebv'),
            iconName: IconNames.API,
            title: 'EBV Sources',
          },
          {
            id: 'api-outputs-config',
            href: makeAdminRoute(orgName, 'library/apiOutputConfigs'),
            iconName: IconNames.IMPORT_EXPORT,
            title: 'API Output Config',
          },
          /// TODO: Uncomment when KG Hub is ready
          // {
          //   id: 'kg',
          //   href: makeAdminRoute('library/knowledge-graph'),
          //   iconName: IconNames.WEBHOOK,
          //   title: 'KG Hub',
          // },
        ],
      },
      {
        id: 'incidents-parent',
        iconName: IconNames.REPORT,
        iconOutlined: true,
        title: 'Incidents',
        children: [
          {
            id: 'incidents',
            href: makeAdminRoute(orgName, 'incidents'),
            iconName: IconNames.REPORT,
            title: 'Incident Reports',
          },
          {
            id: 'incidentConfig',
            href: makeAdminRoute(orgName, 'incidentConfig?sort_dir=asc&sort=category'),
            iconName: IconNames.SETTINGS,
            title: 'Incident types',
          },
          {
            id: 'issues',
            href: makeAdminRoute(orgName, 'issues'),
            iconName: IconNames.FLAG,
            title: 'Issues',
          },
        ],
      },
      {
        id: 'notifications',
        href: makeAdminRoute(orgName, 'notifications'),
        iconName: IconNames.EDIT_NOTIFICATIONS,
        title: 'Notification builder',
      },
      {
        id: 'experiments',
        href: makeAdminRoute(orgName, 'experiments'),
        iconName: IconNames.SCIENCE,
        title: 'Experiments',
      }
    );
  } else {
    // Not admin
    navArr.push({
      href: makeOpsRoute(orgName, 'training'),
      iconName: IconNames.SCHOOL,
      id: 'training',
      title: 'Training',
    });
  }

  return navArr;
};

interface Props {
  isExpanded: boolean;
}

function AppNav({ isExpanded }: Props) {
  const adminRouteMatch = useMatch('/:orgName/admin/:splat/*');
  const libraryRouteMatch = useMatch('/:orgName/admin/library/:splat/*');
  const opsRouteMatch = useMatch('/:orgName/operator/:splat/*');
  const queuePageRouteMatch = useMatch('/:orgName/queue');
  const taskListMatch = useMatch('/:orgName/operator/:task-list/:splat/*');
  const { hasUserType, hasPermission } = useAuth();
  const isAdmin =
    hasUserType([UserType.ADMIN]) && hasPermission([PermissionName.ADMIN_PORTAL_ACCESS]);
  const { orgName: currentOrgName } = useAppState();

  const isUnifiedQueuesFeatureEnabled = useOperatorPortalFeatureEnabled(
    OperatorPortalFeature.UNIFIED_QUEUE
  );

  const canShowTaskList = useCanShowTaskList();

  const showUnifiedQueue = useMemo(() => {
    if (queuePageRouteMatch) return true;
    if (!isUnifiedQueuesFeatureEnabled) return false;
    if (adminRouteMatch && isAdmin) return true;
    if (opsRouteMatch && !!currentOrgName) return true;
    return false;
  }, [
    queuePageRouteMatch,
    isUnifiedQueuesFeatureEnabled,
    adminRouteMatch,
    isAdmin,
    opsRouteMatch,
    currentOrgName,
  ]);

  const navSx = useConditionalSx(baseNavSx, [isExpanded, expandedNavSx]);

  const { navState, setNavItemState } = useNavState();

  const navList = useMemo(
    () =>
      getRoutes({
        isAdmin,
        orgName: currentOrgName,
        showUnifiedQueue,
        canShowTaskList,
        // orgNameForPage,
      }),
    [canShowTaskList, isAdmin, currentOrgName, showUnifiedQueue]
  );

  let match;
  if (libraryRouteMatch) {
    match = libraryRouteMatch;
  } else if (adminRouteMatch) {
    // This will break if/when we add more roles
    match = adminRouteMatch;
  } else if (taskListMatch) {
    match = taskListMatch;
  } else if (opsRouteMatch) {
    match = opsRouteMatch;
    const orgName = match?.params?.orgName;
    if (!orgName) return null;
  } else if (isAdmin) {
    match = adminRouteMatch;
  }
  const splat = match?.params?.splat;

  return (
    <>
      <List sx={navSx}>
        {navList.map((item) => {
          return (
            <NavItem
              isExpanded={isExpanded}
              isOpen={navState.has(item.id)}
              item={item}
              key={item.id}
              onOpenClick={setNavItemState}
              splat={splat}
            />
          );
        })}
      </List>
    </>
  );
}

export default AppNav;
